// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-acknowledgements-js": () => import("./../../../src/pages/acknowledgements.js" /* webpackChunkName: "component---src-pages-acknowledgements-js" */),
  "component---src-pages-for-designers-js": () => import("./../../../src/pages/for-designers.js" /* webpackChunkName: "component---src-pages-for-designers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reset-license-js": () => import("./../../../src/pages/reset-license.js" /* webpackChunkName: "component---src-pages-reset-license-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-featured-set-js": () => import("./../../../src/templates/featured-set.js" /* webpackChunkName: "component---src-templates-featured-set-js" */)
}

