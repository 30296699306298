import Space from '@rebass/space'
import { Link } from 'gatsby'
import { useQueryParam, ModalStateParam } from 'use-query-params'
import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import Logo from './Logo'
import Card from './Card'
import Button from './Button'

import SetappLogo from '../images/vector/setapp.svg'

const StyledMenu = styled.div`
  display: inline-block;
  position: relative;

  a {
    position: relative;
    font-weight: 600;
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#31383c'
        : 'rgba(255, 255, 255, .9)'};
    text-decoration: none;
    padding: 0 0.5rem;

    &:first-child {
      padding-left: 0;
    }

    &:hover,
    &[aria-current='page'] {
      color: ${props =>
        props.theme.appearance === 'light'
          ? '#748086'
          : 'rgba(255, 255, 255, .75)'};
    }
  }
`

const StyledSubMenu = styled(Card)`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 32px;
  transform: perspective(400px) rotateX(-4deg);
  left: 0;
  height: auto;
  transition: all 0.35s ease-in-out;
  z-index: 9999;
  min-width: 360px !important;
  text-align: left;

  /* * {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  } */

  > * {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    &:last-child {
      border-bottom: 0;
    }
  }

  p {
    font-weight: 400;
  }

  svg {
    max-width: inherit !important;
  }
`

const Navigation = props => {
  const [modalState, setModalState] = useQueryParam(
    'thank-you',
    ModalStateParam
  )

  return (
    <Flex flexWrap="wrap" alignItems="center" p={[4, 3]}>
      {/* Logo */}
      <Box width={[1, 1 / 5]}>
        <Link to="/">
          <Logo />
        </Link>
      </Box>

      {/* Menubar */}
      <Box width={[1, 4 / 5]} textAlign={['left', 'right']} mt={[3, 0]}>
        <StyledMenu>
          <Link to="/">Home</Link>
          <Link to="/resources">Resources</Link>
          <Link to="/updates">Updates</Link>
        </StyledMenu>

        <Box display={['none', 'inline-block']}>
          <Space mx={2}>
            <Button as={Link} to="/pricing" colorscheme="green">
              View pricing
            </Button>
          </Space>
          <Space mx={2}>
            <Button
              as="a"
              href="https://go.setapp.com/stp177?refAppID=264&utm_medium=vendor_program&utm_source=IconJar&utm_content=link&utm_campaign=pricing_page_callout&stc=main_nav"
              style={{
                backgroundImage:
                  'linear-gradient(180deg, #5B5867 0%, #3A3844 100%)',
                boxShadow:
                  '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0.5px rgba(0, 0, 0, 0.2), inset 0px -1px 1px rgba(0, 0, 0, 0.05)'
              }}>
              <div>
                <span>Use with </span>{' '}
                <SetappLogo
                  style={{
                    fill: '#FFF',
                    width: '64px'
                  }}
                />{' '}
              </div>
            </Button>
          </Space>
        </Box>
      </Box>
    </Flex>
  )
}

export default Navigation
