import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled, { ThemeConsumer } from 'styled-components'
import { Flex } from 'reflexbox'
import Card from './Card'

const StyledPosition = styled.div`
  position: fixed;
  z-index: 1991;
  max-width: calc(100vw - 4rem);
  bottom: 2rem;
  left: 2rem;
  p {
    max-width: 270px;
  }
`

const CloseIcon = styled.button`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
`

class CookieNotice extends Component {
  constructor(props) {
    super(props)
    this.state = { cookieConsent: false }
    this.handleConsent = this.handleConsent.bind(this)
  }

  handleConsent(e) {
    this.setState({ cookieConsent: true })
    localStorage.setItem('cookieConsent', true)
  }

  async componentDidMount() {
    if (localStorage.getItem('cookieConsent')) {
      this.setState({ cookieConsent: true })
    }
  }

  render() {
    return (
      <ThemeConsumer>
        {theme => (
          <StyledPosition>
            {typeof window !== 'undefined' &&
              localStorage.getItem('cookieConsent') === null && (
                <Flex>
                  <Card p={3}>
                    <CloseIcon onClick={this.handleConsent}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.295 8.705C14.9056 8.31564 14.2744 8.31564 13.885 8.705L12 10.59L10.115 8.705C9.72564 8.31564 9.09436 8.31564 8.705 8.705V8.705C8.31564 9.09436 8.31564 9.72564 8.705 10.115L10.59 12L8.705 13.885C8.31564 14.2744 8.31564 14.9056 8.705 15.295V15.295C9.09436 15.6844 9.72564 15.6844 10.115 15.295L12 13.41L13.885 15.295C14.2744 15.6844 14.9056 15.6844 15.295 15.295V15.295C15.6844 14.9056 15.6844 14.2744 15.295 13.885L13.41 12L15.295 10.115C15.6844 9.72564 15.6844 9.09436 15.295 8.705V8.705ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                          fill={
                            theme.appearance === 'light' ? '#31383C' : '#FFF'
                          }
                        />
                      </svg>
                    </CloseIcon>
                    <h5>
                      <span role="img" aria-label="cookie-emoji">
                        🍪
                      </span>{' '}
                      Our site uses cookies
                    </h5>
                    <p className="small">
                      We use cookies to improve our website.
                    </p>
                    <p className="small" style={{ marginBottom: 0 }}>
                      View our{' '}
                      <Link to="/privacy-policy#cookies">privacy policy</Link>{' '}
                      for more info.
                    </p>
                  </Card>
                </Flex>
              )}
          </StyledPosition>
        )}
      </ThemeConsumer>
    )
  }
}

export default CookieNotice
