import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useQueryParam, ModalStateParam } from 'use-query-params'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import { ThemeConsumer } from 'styled-components'
import Card from './Card'
import Button from '../components/Button'

// Visual assets
import IconDismiss from '../images/vector/icon-dismiss.svg'
import ThankYouBg from '../images/thank-you-bg.jpg'
import LogoUI8 from '../images/vector/ui8-on-white.svg'
import LogoSmashicons from '../images/vector/smashicons.svg'
import LogoGumroad from '../images/vector/gumroad.svg'
import LogoCraftwork from '../images/vector/craftwork-glyph.svg'
import LogoIconfinder from '../images/vector/iconfinder-glyph.svg'

const StyledCurtain = styled(Flex)`
  opacity: 0;
  display: none;
  visibility: hidden;
  position: fixed;
  z-index: 1250;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(88, 101, 108, 0.75);
  transition: all 0.2s ease-in-out;

  [class*='Card-'] {
    width: 680px;
    max-width: 100%;
    margin-top: -16px;
    transition: all 0.35s ease-in-out;
  }

  &.shown {
    opacity: 1;
    display: block;
    visibility: inherit;

    [class*='Card-'] {
      margin-top: 0;
    }
  }
`

const StyledIconCallout = styled(Box)`
  background: url(${ThankYouBg}) no-repeat;
  background-position: center center;
  background-size: auto 100%;
  position: relative;

  .gatsby-image-wrapper {
    position: absolute;
    left: calc(50% - 64px);
    top: calc(50% - 64px);
  }

  div {
    position: absolute;
    top: 12px;
    right: 12px;
    button {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      padding: 0;
      border: 0;
      outline: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
`

const ThankYouModal = ({ props }) => {
  // Fetch images
  const data = useStaticQuery(graphql`
    query {
      appIcon: file(relativePath: { eq: "app-icon@2x.png" }) {
        childImageSharp {
          fixed(width: 128, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [modalState, setModalState] = useQueryParam(
    'thank-you',
    ModalStateParam
  )

  function dismissModal() {
    setModalState(undefined)
  }

  if (modalState === 'true') {
    setTimeout(function() {
      window.location.href = 'https://geticonjar.com/download'
    }, 350)
  }

  return (
    <ThemeConsumer>
      {theme => (
        <StyledCurtain className={modalState === 'true' ? 'shown' : 'hidden'}>
          <Flex
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}>
            <Card style={{ height: 'auto', borderWidth: 0 }}>
              <Flex>
                <Box width={[3 / 5]} p="4">
                  <h4>Thank you 🙏</h4>

                  <Box as="p" className="small" mb="3">
                    <strong>
                      Your download will begin automatically. If it doesn't,{' '}
                      <a href="./download">click here</a>.
                    </strong>
                  </Box>

                  <hr />

                  <p className="small faded">
                    IconJar is pre-loaded with Google Material Icons, but the
                    full potential of our app is realized with an extensive
                    collection of icons.
                  </p>
                  <Box pt="2">
                    <Flex alignItems="center" mb={3}>
                      <Box mr="3">
                        <a href="https://www.ui8.net/?rel=icnjr">
                          <LogoUI8 />
                        </a>
                      </Box>
                      <Box mr="3">
                        <a href="https://smashicons.com">
                          <LogoSmashicons width="44" />
                        </a>
                      </Box>
                      <Box mr="3">
                        <a href="https://iconfinder.com">
                          <LogoIconfinder width="30" />
                        </a>
                      </Box>
                      <Box mr="3">
                        <a href="https://craftwork.design/?ref=333">
                          <LogoCraftwork width="30" />
                        </a>
                      </Box>
                      <Box>
                        <a href="https://discover.gumroad.com/design/icons?a=581296979">
                          <LogoGumroad />
                        </a>
                      </Box>
                    </Flex>
                    <Button
                      as="a"
                      href="./resources"
                      colorscheme="white"
                      display="inline-block">
                      Browse our resources →
                    </Button>
                  </Box>
                </Box>
                <StyledIconCallout width={[2 / 5]}>
                  <div>
                    <Button colorscheme="white" role="button">
                      <IconDismiss onClick={dismissModal} />
                    </Button>
                  </div>
                  <Img
                    fixed={data.appIcon.childImageSharp.fixed}
                    alt="IconJar logo"
                  />
                </StyledIconCallout>
              </Flex>
            </Card>
          </Flex>
        </StyledCurtain>
      )}
    </ThemeConsumer>
  )
}

export default ThankYouModal
