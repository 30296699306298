import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@500;600&display=swap'); 

  * {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  }

  html, body {
    margin: 0;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    background: ${props =>
      props.theme.appearance === 'light'
        ? 'linear-gradient(180deg, #F2F9FE 0%, rgba(255, 255, 255, 0) 100%)'
        : 'linear-gradient(180deg, #2B2C2D 0%, #343639 100%)'};
    background-color: ${props =>
      props.theme.appearance === 'light' ? '#FFF' : '#343639'};
    background-size: 100% 500px;
    background-repeat: no-repeat;
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#4C5153'
        : 'rgba(255, 255, 255, 0.75)'};
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  }

  img,
  svg,
  picture,
  video,
  .gatsby-image-wrapper {
    max-width: 100% !important;
    vertical-align: middle;
  }

  .gatsby-image-wrapper {
    background-color: transparent !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    line-height: 1.25em;
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#31383C'
        : 'rgba(255, 255, 255, .9)'};
    font-weight: 800;
  }

  h1 {
    font-size: 2.8em;
    font-weight: 800;
    margin: 0 auto 1rem auto;
    max-width: 600px;
  }

  h2 {
    font-size: 1.75em;
    margin: 0 0 1rem 0;
  }

  h3 {
    font-size: 1.6em;
    margin: 0 0 0.75rem 0;
  }

  h4 {
    font-size: 1.4em;
    margin: 0 0 0.5rem 0;
  }

  h5 {
    font-size: 1.25em;
    margin: 0 0 0.5rem 0;
  }

  h6 {
    font-size: 1.1em;
    margin: 0 0 0.5rem 0;
  }

  label {
    font-size: 1em;
    display: block;
    margin-bottom: 0.5rem;
  }

  .handwritten {
    font-family: 'Edu TAS Beginner', cursive;
    font-size: 1.1rem;
    color: #836F44 !important;
  }

  code {
    font-family: 'SF Mono', SFMono-Regular, ui-monospace, 'DejaVu Sans Mono', Menlo, Consolas, monospace;
    font-size: 0.85rem;
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 3px 8px;
  }

  p,
  ul,
  ol {
    margin: 0 0 1em 0;
  }

    p:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: 0;
    }

  ul, ol {
    padding-left: 0 !important;
  }

  ul li{
    margin: 0 0 8px 16px;
    padding-left: 4px;
    list-style-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI1IiBjeT0iNSIgcj0iNCIgZmlsbD0iI0M0QzRDNCIgZmlsbC1vcGFjaXR5PSIwLjAxIiBzdHJva2U9IiMxNkE1RjMiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==');
  }

  p.subtitle {
    font-size: 1.25em;
  }

  p.small,
  span.small {
    font-size: 0.9rem;
  }

  .faded {
    opacity: 0.65;
  }

  p a,
  label a,
  li a {
    color: #25AAF3;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong,
  b {
    font-weight: 600;
  }

  a {
    cursor: pointer;
  }

  .block-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    flex-wrap: wrap;
  }

  .center {
    text-align: center;
  }

  a.css-vurnku { // These are the feature icon cards
    text-decoration: none;
  }

  a.trigger:hover > * {
    display: block;
    opacity: 1;
    visibility: inherit;
    transform: perspective(0) rotateX(0); 
  }

  input[type=text],
  input[type=email],
  input[type=number],
  textarea,
  select {
    -webkit-appearance: none;
    font-size: 1em;
    height: 44px;
    line-height: 42px;
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#31383C'
        : 'rgba(255, 255, 255, .9)'};
    border: 1px solid ${props =>
      props.theme.appearance === 'light' ? '#DCDFE1' : '#1D1E1F'};
    background-color: ${props =>
      props.theme.appearance === 'light' ? '#FFF' : '#373839'};
    box-sizing: border-box;
    box-shadow: ${props =>
      props.theme.appearance === 'light'
        ? '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -1px 1px rgba(0, 0, 0, 0.03)'
        : 'rgba(255,255,255,0.12) 0px 1px 1px 0px, rgba(0,0,0,0.15) 0px 1px 3px, rgba(0,0,0,0.05) 0px 1px 2px 1px inset'};
    border-radius: 8px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    width: 100%;

    &:focus {
      outline: 0;
      border-color: ${props =>
        props.theme.appearance === 'light'
          ? '#25AAF3'
          : 'rgba(255, 255, 255, .1)'};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07), inset 0px -1px 1px rgba(0, 0, 0, 0.03);
    }
  }

    textarea {
      padding-top: 8px;
      padding-bottom: 8px;
      min-height: 128px;
      resize: vertical;
      line-height: 1.5;
    }

    select {
      background-image: url('data:image/svg+xml;utf8,<svg width="44" height="44" xmlns="http://www.w3.org/2000/svg"><g stroke="#31383C" stroke-width="1.3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M25 20l-2.786-2.63L19.43 20M25 24l-2.786 2.63L19.43 24"/></g></svg>');
      background-repeat: no-repeat;
      background-position: right center;
    }

  ::-webkit-input-placeholder {
    color: #748086;
  }

  ::-moz-placeholder {
    color: #748086;
  }

  :-ms-input-placeholder {
    color: #748086;
  }

  :-moz-placeholder {
    color: #748086;
  }

  hr {
    border: 0;
    border-top: 2px solid #f5f5f5;
    margin: 1.5em 0;
    padding: 0;
  }

  .gatsby-image-wrapper {
    overflow: auto !important;
  }

  [data-tippy-root] > * {
    padding: 8px;
    border-radius: 8px !important;
  }

  @media screen and (max-width: 40em) {
    .xs-hide { display: none !important }
  }

  @media screen and (max-width: 832px) {

    .app-preview {
      transform: scale(1.15);
    }

    .payment-options {
      border-left: 0 !important;
      border-top: 1px solid;
      border-color: ${props =>
        props.theme.appearance === 'light' ? '#EFEFEF' : 'rgba(0, 0, 0, .15)'};
    }
  }
`

export default GlobalStyle
