import { Link } from 'gatsby'
import React from 'react'
import styled, { ThemeConsumer } from 'styled-components'

// Grid
import Container from './Container'
import { Flex, Box } from 'reflexbox'

// images
import IconX from '../images/vector/x-logo.svg'
import IconEmail from '../images/vector/icon-email.svg'
import IconFacebook from '../images/vector/icon-facebook.svg'

const StyledFlex = styled(Flex)`
  a {
    display: inline-block;
    font-weight: 600;
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#31383c'
        : 'rgba(255, 255, 255, .9)'};
    text-decoration: none;

    &:hover,
    &[aria-current='page'] {
      color: ${props =>
        props.theme.appearance === 'light'
          ? '#748086'
          : 'rgba(255, 255, 255, .75)'};
    }
  }

  p {
    margin-bottom: 0;
  }

  .secondary-nav a {
    margin-right: 1rem;
  }
`

const Footer = () => {
  return (
    <ThemeConsumer>
      {theme => (
        <Container pb={4}>
          <StyledFlex
            flexWrap="wrap"
            px={[0, 3]}
            flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 1 / 2]}>
              <p>
                A project by{' '}
                <a href="https://x.com/daveyheuser" rel="nofollow">
                  Davey Heuser
                </a>{' '}
                and{' '}
                <a href="https://mstdn.social/@curthard89" rel="nofollow">
                  Curtis Hard
                </a>
              </p>
              <Flex mt={2} alignItem="center">
                <Box
                  as="a"
                  mr={2}
                  href="https://x.com/geticonjar"
                  rel="nofollow">
                  <IconX width="16" height="16" />
                </Box>
                <Box
                  as="a"
                  mr={3}
                  href="https://facebook.com/geticonjar"
                  rel="nofollow">
                  <IconFacebook
                    style={{
                      fill: theme.appearance === 'light' ? '#3b5998' : '#FFF'
                    }}
                  />
                </Box>
                <Box
                  pl={2}
                  as="a"
                  href="mailto:info@geticonjar.com"
                  rel="nofollow"
                  style={{
                    borderLeft:
                      theme.appearance === 'light'
                        ? '1px solid rgba(0, 0, 0, .15)'
                        : '1px solid rgba(255, 255, 255, .15)'
                  }}>
                  <IconEmail
                    style={{
                      fill: theme.appearance === 'light' ? '#16a5f3' : '#FFF',
                      marginRight: '8px',
                      marginTop: '-2px'
                    }}
                  />
                  <span
                    style={{
                      color: theme.appearance === 'light' ? '#16a5f3' : '#FFF'
                    }}>
                    Email us
                  </span>
                </Box>
              </Flex>
            </Box>
            <Box
              width={[1, 1 / 2]}
              textAlign={['left', 'right']}
              className="secondary-nav"
              mb={[3, 0]}>
              <p>
                <Link to="/terms-of-use">Terms of use</Link>
                <Link to="/privacy-policy">Privacy policy</Link>
                <Link to="/updates">Updates</Link>
                <a href="/support">Support</a>
              </p>
            </Box>
          </StyledFlex>
        </Container>
      )}
    </ThemeConsumer>
  )
}

export default Footer
