import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { motion } from 'framer-motion'
import { Box } from 'reflexbox'

const backgroundColor = theme('appearance', {
  light: '#FFF',
  dark: '#2B2C2D'
})

const borderColor = theme('appearance', {
  light: '#DCDFE1',
  dark: '#232426'
})

const boxShadow = theme('appearance', {
  light:
    '0px 6px 100px rgba(0, 0, 0, 0.07), 0px 2.34px 22.3363px rgba(0, 0, 0, 0.0417275), 0px 2.4px 6.6501px rgba(0, 0, 0, 0.0282725)',
  dark: '0px 1px 3px rgba(0, 0, 0, 0.10), 0px 1px 6px rgba(0, 0, 0, 0.1)'
})

const boxShadowOnHover = theme('appearance', {
  light:
    '0px 9px 100px rgba(0, 0, 0, 0.08), 0px 5px 22.3363px rgba(0, 0, 0, 0.05), 0px 5px 6.6501px rgba(0, 0, 0, 0.03)',
  dark: '0px 1px 3px rgba(0, 0, 0, 0.10), 0px 1px 6px rgba(0, 0, 0, 0.1)'
})

const StyledCard = styled(Box)`
  position: relative;
  background: ${backgroundColor};
  border: 1px solid ${borderColor};
  box-shadow: ${boxShadow};
  border-radius: 12px;
  overflow: hidden;
  height: 100%;

  &:hover {
    border: 1px solid ${borderColor};
    background-color: ${props =>
      props.animateonhover === 'true' ? backgroundColor : ''};
    box-shadow: ${props =>
      props.animateonhover === 'true' ? boxShadowOnHover : ''};
  }

  .gatsby-image-wrapper {
    background-color: #fff;
  }

  p {
    color: ${props =>
      props.theme.appearance === 'light'
        ? '#31383c'
        : 'rgba(255, 255, 255, .75)'};
  }
`

const Card = props => {
  const shouldAnimate = props.animateonhover

  const animProps = {
    scale: 1.03
  }

  return shouldAnimate ? (
    <motion.div style={{ borderRadius: '8px' }} whileHover={animProps}>
      <StyledCard {...props}>{props.children}</StyledCard>
    </motion.div>
  ) : (
    <StyledCard {...props}>{props.children}</StyledCard>
  )
}

export default Card
