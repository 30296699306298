import React from 'react'
import PropTypes from 'prop-types'
import Navigation from './Navigation'
import Footer from './Footer'
import ParallaxBg from './ParallaxBg'
import CookieNotice from './CookieNotice'
import ThankYouModal from './ThankYouModal'
import GlobalStyle from '../theme/globalStyles'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />

      <Navigation />
      <ParallaxBg />
      {children}
      <Footer />

      <CookieNotice />
      <ThankYouModal />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
