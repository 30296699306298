import React from 'react'
import Layout from './src/components/Layout'
import ThemeWrapper from './src/components/ThemeWrapper'

export const wrapRootElement = ({ element }) => {
  return <ThemeWrapper>{element}</ThemeWrapper>
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // if (window.Intercom) {
  //   window.Intercom('update', {
  //     last_request_at: parseInt(new Date().getTime() / 1000)
  //   })
  // }
}
