import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'

class ThemeWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { lightAppearance: true }
    this.updateAppearance = this.updateAppearance.bind(this)
  }

  updateAppearance(e) {
    let mql = window.matchMedia('(prefers-color-scheme: light)')
    this.setState({ lightAppearance: mql.matches })
  }

  componentDidMount() {
    let mql = window.matchMedia('(prefers-color-scheme: light)')
    this.updateAppearance()
    mql.addListener(this.updateAppearance)
  }

  render() {
    const { children } = this.props

    return (
      <ThemeProvider
        theme={{ appearance: this.state.lightAppearance ? 'light' : 'light' }}>
        {children}
      </ThemeProvider>
    )
  }
}

export default ThemeWrapper
