import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { ThemeConsumer } from 'styled-components'

const LogoComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      light: file(relativePath: { eq: "logo@2x.png" }) {
        childImageSharp {
          fixed(width: 144, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      dark: file(relativePath: { eq: "logo-dark@2x.png" }) {
        childImageSharp {
          fixed(width: 144, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <ThemeConsumer>
      {theme => (
        <Img
          fixed={
            theme.appearance === 'light'
              ? data.light.childImageSharp.fixed
              : data.dark.childImageSharp.fixed
          }
          alt="IconJar logo"
        />
      )}
    </ThemeConsumer>
  )
}

export default LogoComponent
