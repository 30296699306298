import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Plx from 'react-plx'
import Img from 'gatsby-image'
import { Box } from 'reflexbox'
import styled from 'styled-components'

const StyledParallaxWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: ${props => (props.theme.appearance === 'light' ? '' : '0.5')};

  .icon-1,
  .icon-2,
  .icon-3,
  .icon-4,
  .icon-5 {
    position: absolute;
    filter: ${props =>
      props.theme.appearance === 'light' ? '' : 'grayscale(100)'};
  }

  .icon-1 {
    width: 141px;
    left: 360px;
    > * {
      opacity: 0.25;
      transform: rotate(6deg);
    }
  }

  .icon-2 {
    width: 128px;
    left: 25px;
    > * {
      opacity: 0.33;
      transform: rotate(-10deg);
    }
  }

  .icon-3 {
    width: 42px;
    left: 175px;
    > * {
      opacity: 0.33;
      transform: rotate(-10deg);
    }
  }

  .icon-4 {
    width: 60px;
    right: 75px;
    > * {
      opacity: 0.2;
      transform: rotate(-20deg);
    }
  }

  .icon-5 {
    width: 150px;
    right: 125px;
    > * {
      opacity: 0.33;
      transform: rotate(-13deg);
    }
  }
`

const Parallaxbg = props => {
  const data = useStaticQuery(graphql`
    query {
      folderB6: file(relativePath: { eq: "hero/folder-b6@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 141, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      folderB10: file(relativePath: { eq: "hero/folder-b10@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 141, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      folderB18: file(relativePath: { eq: "hero/folder-b18@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 141, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const parItem1 = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 300,
          endValue: 250,
          property: 'translateY'
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity'
        }
      ]
    }
  ]

  const parItem2 = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 532,
          endValue: 500,
          property: 'translateY'
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity'
        }
      ]
    }
  ]

  const parItem3 = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 300,
          endValue: 360,
          property: 'translateY'
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity'
        }
      ]
    }
  ]

  const parItem4 = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 125,
          endValue: 75,
          property: 'translateY'
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity'
        }
      ]
    }
  ]

  const parItem5 = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 425,
          endValue: 350,
          property: 'translateY'
        },
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity'
        }
      ]
    }
  ]

  return (
    <StyledParallaxWrapper display={['none', 'none', 'inherit']}>
      <Plx className="icon-1" parallaxData={parItem1}>
        <Img
          fluid={data.folderB6.childImageSharp.fluid}
          alt="Blurred folder icon"
        />
      </Plx>
      <Plx className="icon-2" parallaxData={parItem2}>
        <Img
          fluid={data.folderB10.childImageSharp.fluid}
          alt="Blurred folder icon"
        />
      </Plx>
      <Plx className="icon-3" parallaxData={parItem3}>
        <Img
          fluid={data.folderB18.childImageSharp.fluid}
          alt="Blurred folder icon"
        />
      </Plx>
      <Plx className="icon-4" parallaxData={parItem4}>
        <Img
          fluid={data.folderB10.childImageSharp.fluid}
          alt="Blurred folder icon"
        />
      </Plx>
      <Plx className="icon-5" parallaxData={parItem5}>
        <Img
          fluid={data.folderB10.childImageSharp.fluid}
          alt="Blurred folder icon"
        />
      </Plx>
    </StyledParallaxWrapper>
  )
}

export default Parallaxbg
